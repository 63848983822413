import React, { Component } from 'react';

import { gsap, Quad } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

import screens from '../images/screens.png';
import guy from '../images/Guy.png';

import { translate } from '../i18n/translate';

class Playa extends Component {

    componentDidMount(){
        gsap.registerPlugin(TextPlugin);

        //GSAP animation for the heading elements
        const { header, gamer, screen } = this;
        // draw in
        gsap.from(header,1,{text:'',delay:.5});
        // blink
        gsap.set(header,{opacity:0,delay:2.5});
        gsap.set(header,{opacity:1,delay:2.6});
        gsap.set(header,{opacity:0,delay:2.7});
        gsap.set(header,{opacity:1,delay:2.8});
        gsap.set(header,{opacity:0,delay:2.9});
        gsap.set(header,{opacity:1,delay:3});
        gsap.to(header,{opacity:0,delay:3.1});

        gsap.from(screen,{opacity:0,delay:3});
        gsap.from(gamer,{opacity:0,y:100,delay:3.5,onComplete:this.reTarget,onCompleteParams:[this.gamer,this.reTarget]});
    }
    componentWillUnmount(){
        gsap.killTweensOf(this.gamer);
    }
    reTarget(gamer,onComplete){
        // console.log('retarget!',gamer);
        const xTarg = -50 + Math.random()*100;
        const yTarg = -30 + Math.random()*60;
        gsap.to(gamer,1,{x:xTarg,y:yTarg,ease:Quad.easeInOut,onComplete:onComplete,onCompleteParams:[gamer,onComplete]});
    }

    render() {


        return (
            <div className="playa-wrapper">
                <h2 className="gameon" ref={el=>{this.header=el}}>{translate('homepage.gameon')}</h2>
                <img src={screens} className="screens" alt="check out my set up" ref={el=>{this.screen=el}} />
                <img src={guy} className="gamer" alt="gamer" ref={el=>{this.gamer=el}} />
            </div>
        )
    }
}

export default Playa;