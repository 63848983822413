import React, { Component, Fragment } from 'react';
// import { NavLink } from 'react-router-dom';

//Language files
import { LOCALES } from '../../i18n';

import Nav from './Nav';

//image import
import Cadbury from '../../images/Cadbury_final.png';
import ChipsAhoy from '../../images/CA_final.png';
import Trident from '../../images/Trident_Logo_H_2C_SPOT.png';
import Crispers from '../../images/Crispers_final.png';
import BurgerBar from '../../images/burger bar.png';

//CSS
import './header.scss';
// import { translate } from '../../i18n/translate';

class Header extends Component{

    state={
        menuOpen: false,
        langSelect : "FR"
    }

    // //FR click handler
    // clickFrButton = () =>{
    //     const urlPath = window.location.pathname;

    //     //check if 'fr' is in the link
    //     const fr = urlPath.substring(1,3);

    //     if(fr === "fr"){
    //         const englishLink = urlPath.substring(3);
    //         window.location = englishLink;

    //         this.setState({
    //             langSelect: "FR"
    //         })
    //     } else{
    //         const frenchLink = `/fr${urlPath}`;
    //         window.location = frenchLink;

    //         this.setState({
    //             langSelect: "EN"
    //         })
    //     }
    // }

    menuClickHandler = () => {
        this.setState((prevState) => {
            return {menuOpen: !prevState.menuOpen}
        })
    }

    //change language 


    render(){

        const { langChangeHandle, activeEN } = this.props;
        // const locale = activeEN ? LOCALES.FRENCH : LOCALES.ENGLISH;
        // const langLabel = activeEN ? 'FR' : 'EN';
        
        return(
            <Fragment>
                <header>
                    <Nav className={this.state.menuOpen ? "nav-opened" : "nav-closed" } menuClickHandler={this.menuClickHandler} menuOpen={this.state.menuOpen}></Nav>
                    <div className="main-header" id="main-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2 menu">
                                    <button onClick={this.menuClickHandler} style={{display:'block'}} aria-label="Menu">
                                        <img src={BurgerBar} alt="open menu"/>
                                    </button>
                                </div>
                                <div className="col-md-8 logo">
                                <span><span><img src={Cadbury} alt="Cadbury" /></span></span>
                                <span><span><img src={ChipsAhoy} alt="Chips Ahoy!" /></span></span>
                                <span><span><img src={Trident} alt="Trident" /></span></span>
                                <span><span><img src={Crispers} alt="Crispers" /></span></span>
                                </div>
                                <div className="col-md-2 lang">
                                    <button onClick={() => langChangeHandle(LOCALES.ENGLISH)} className={activeEN?'selected':''}>EN</button> • 
                                    <button onClick={() => langChangeHandle(LOCALES.FRENCH)} className={activeEN?'':'selected'}>FR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
        )
    }
}

export default Header;