import React, { Component, Fragment } from 'react';
import { OutboundLink } from 'react-ga';
import { NavLink } from 'react-router-dom';

//language file
import { translate } from '../../i18n/translate';

import MondelezLogo from '../../images/mondelez.png';

import './footer.scss';

class Footer extends Component{

    render(){
        return(
            <Fragment>
                <footer>
                    <div className="site-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="footer-links">
                                        <li>
                                            <NavLink exact to="/rules">{translate('footer.link1')}</NavLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Privacy Policy"
                                                to={this.props.lang === "fr-CA" ? "https://disclaimer.mondelezinternational.com/ca/privacy_policy_fr.aspx" : "https://disclaimer.mondelezinternational.com/ca/privacy_policy_en.aspx"}
                                                target="_blank"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link2')}   
                                            </OutboundLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer terms and conditions"
                                                to={this.props.lang === "fr-CA" ? "https://disclaimer.mondelezinternational.com/ca/terms_of_use_fr.aspx" : "https://disclaimer.mondelezinternational.com/ca/terms_of_use_en.aspx"}
                                                target="_blank"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link3')}  
                                            </OutboundLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Accessibility Policy"
                                                to={this.props.lang === "fr-CA" ? "https://www.mondelezinternational.com/-/media/Mondelez/Country/Canada/French/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf" : "https://www.mondelezinternational.com/-/media/Mondelez/Country/Canada/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf"}
                                                target="_blank"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link4')}  
                                            </OutboundLink>
                                        </li>
                                        <li>
                                            <OutboundLink eventLabel="Footer Contact Us"
                                                to={this.props.lang === "fr-CA" ? "https://www.mdlzcusthelp.ca/fr-CA" : "https://www.mdlzcusthelp.ca/en-CA"}
                                                target="_blank"
                                                trackerNames={['tracker2']}>
                                                     {translate('footer.link5')}  
                                            </OutboundLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mondelez-logo">
                                <div className="col-md-12">
                                    <div className="mondelez-logo-container">
                                        <img src={MondelezLogo} alt="Mondelez Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;