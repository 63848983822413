import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { TweenMax, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

import products from '../../images/packages.png';

class Products extends Component{

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { heading, prize1, prize1desc, prize2, prize2desc } = this;
        TweenMax.staggerFrom([heading, prize1, prize1desc, prize2, prize2desc], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.2)
    }

    render(){
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper prize-details">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading">
                                        <h1 ref={el =>{this.heading = el}} className="text-center">{translate('products')}</h1>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <img src={products} ref={el => {this.prize1 = el}} alt="Participating Products" className="img-fluid" />
                                        </div>
                                        <div className="col-12 product-list">
                                            <h2>{translate('products.group1.title')}</h2>
                                            <p>{translate('products.group1', {br: <br />})}</p>
                                            <h2>{translate('products.group2.title')}</h2>
                                            <p>{translate('products.group2', {br: <br />})}</p>
                                            <h2>{translate('products.group3.title')}</h2>
                                            <p>{translate('products.group3', {br: <br />})}</p>
                                        </div>
                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Products;