import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'Snack on with XBOX!',
        'close': 'Close',

        //header

        //nav
        'menu.item.home' : 'HOME',
        'menu.item.winner' : 'PAST WINNERS',
        'menu.item.1': 'ENTER CONTEST',
        'menu.item.2': 'PRIZE DETAILS',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RULES',
        'menu.item.5': 'PARTICIPATING PRODUCTS',

        'contact-email': 'info@snackonwithxboxcontest.ca',

        //footer
        'footer.link1' : 'Rules',
        'footer.link2' : 'Privacy Policy',
        'footer.link3' : 'Terms & Conditions',
        'footer.link4' : 'Accessibility Policy',
        'footer.link5' : 'Contact Us',

        'legal' : '*NO PURCHASE NECESSARY. Open to residents of Canada (age of majority). Closes Dec 17/22 (11:59:59 p.m. ET). Enter online and full rules at: {link} 100 Daily PC Game Pass prizes per day [ARV: $11.99 CAD each // must be used by Jan 31/23 // credit card required to use PC Game Pass, which requires an on-going subscription (cancel within 30 days to avoid charges) // limit 1 Daily PC Game Pass prize per day // limit 2 Daily PC Game Pass prizes during the promotion]. 2 Grand Prizes [ARV: $5000 CAD each // limit 1 Grand Prize per person]. Trip must be taken between March 1 - April 30, 2023. Odds depend on number of eligible entries. Skill-testing question required. Trademarks are property of their respective owners. Xbox is a registered trademark of Microsoft. Microsoft is not a sponsor.',
        'legal.link' : 'www.SnackOnWithXbox.ca',

        //homapge
        'homepage.gameon' : 'GAME ON!',
        'homepage.unlock': 'YOU COULD',
        'homepage.prize' : 'WIN<sup>*</sup> <small>AN</small> XBOX GAME STUDIOS TRIP TO L.A.',
        'homepage.pluscash': 'Plus 100 Daily PC Game Pass Prizes',
        'homepage.cta' : 'Scroll To Learn How To Enter',
        'homepage.howtoenter' : 'HOW IT WORKS',
        'homepage.step1head' : 'STEP 1',
        'homepage.step1' : 'Buy participating Mondelez Canada product',
        'homepage.step2head' : 'STEP 2',
        'homepage.step2' : 'Look for the 11- digit product UPC numbers** found on your participating Mondelez product package. <small>**Omit first number to left of the code.</small>',
        'homepage.step3head' : 'STEP 3',
        'homepage.step3' : 'Enter UPC for a chance to win',

        'contestClosedHead' : 'CONTEST CLOSED',
        'contestClosedText' : 'Thanks For Entering! The Mondelez Canada “You Could Win An Xbox Studios Trip To L.A.” Contest Has Ended.',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',

        'enterNow' : 'ENTER',

         //coming soon and home page
        'comingsoon' : 'LOOK FOR OUR RETAIL DISPLAYS STARTING OCTOBER{nbsp}9TH,{nbsp}2022 ',

        //enter contest page
        'step1': 'Step 1',
        'enterPin': 'ENTER UPC',
        'enterKeyDescription': 'Enter the 11- digit product UPC numbers** found on your ',
        'enterKeyLink': 'participating Mondelez product package.',
        'enterKeySmall': '**Omit first number to left of the code.',
        'popupBodytext' : 'Look for the UPC code on the back of your purchase of a ',
        'popupBodylink' : 'participating product.',
        'pin': 'UPC CODE',
        'nextStep': 'NEXT STEP',

        'step2': 'Step 2',
        'enterInfo': 'ENTRY INFORMATION',
        'enterInfoDescription': 'Provide your contest entry information to enter and to see if you have won a daily prize.',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'emailAddress': 'Email Address',
        'infoForm.age': 'I confirm that I am of the age of majority in the province/territory where I reside*',
        'infoForm.rules' : 'I have read and confirm compliance with the ',
            'infoForm.rules.link1' : 'Contest rules and regulations',
            'infoForm.rules2' : ' and the ',
            'infoForm.rules.link2' : 'Mondelēz Privacy Policy*',
            'infoForm.rules3' : ' ',
        'infoForm.optin' : 'I confirm my consent to Mondelez Canada Inc. storing, sharing and using my personal information submitted for the purpose of administering the Contest (including notifying me if I am selected as an eligible winner of a prize) in accordance with the Contest Rules and Regulations and the Mondelez Privacy Policy*',
        'requiredFields' : '*Required fields',
        'submit': 'Submit',



        //winning page
        'congratulation' : 'ALMOST THERE!',
        'winningPage.heading' : 'YOU’RE A POTENTIAL WINNER OF AN XBOX PC GAME PASS!',
        'winningPage.body' : 'Before being declared an official winner, you must correctly answer, unaided, the mathematical skill-testing question below.',
        'winningPage.question' : '(6X6) – 16, then add 5 =',
        'winningPage.answer' : "Your Answer",
        'winningPage.cta' : 'SUBMIT',
        'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'CONGRATULATIONS!',
        'winningPage.heading2' : 'YOU’RE A WINNER OF AN XBOX PC GAME PASS!',
        'winningPage.body2' : 'You will receive the PC Game Pass to the email provided within 2-3 days.{br}Plus, you have been entered into the draw for the Grand Prize!{br}{br}If you do not receive the PC Game Pass email within 2-3 days, please check your email and spam filters or contact us at ',
        'winningPage.body2a' : '.{br}{br}Note: Credit card required to use PC Game Pass, which requires an on-going subscription, (cancel within 30 days to avoid charges).',

        'winningPage.heading3' : 'SORRY, INCORRECT ANSWER.',
        'winningPage.body3' : 'Please Try Again with another UPC code',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Download',


        //losing page
        'tryAgain' : 'THANKS FOR ENTERING THE CONTEST!',
        'tryAgain.body' : 'You have successfully been entered into the next draw for a chance to WIN* AN XBOX GAME STUDIOS TRIP TO L.A.{br}{br}You are not a daily winner but there are still PC Game Pass prizes to be won.{br}{br}Enter another UPC found on participating packs for another chance to win*.',
        'tryAgain.btn' : 'TRY AGAIN', 
        'tryAgain.heading2' : 'SHARE THE FUN',
        'tryAgain.body2' : 'Get your crew in on the action! Share the contest with your friends and let them know there are still XBOX prizes to be won!',
        'tryAgain.body3' : 'Want to share to Instagram? Click the download button below and receive an image you can upload to your Instagram account.',

        //Prize Details
        'prizeDetails' : 'PRIZE DETAILS',

        'prizeDetails-heading1' : 'GRAND PRIZE',
        'prizeDetails-subheading1' : 'Experience the magic of an award-winning Game Studio!',
        'prizeDetails-body1' : 'Each winner’s trip for 2 to OBSIDIAN STUDIOS in LA. will see you and a friend be welcomed like VIPs, taking a studio tour, playing the latest Obsidian games, and receiving gift bags on departure. Prize also includes an XBOX Elite Wireless Controller Series 2 and a PC Game Pass one year subscription.',

        'prizeDetails-heading2' : 'DAILY PRIZES',
        'prizeDetails-subheading2' : '100 XBOX PC Game Pass Prizes to be WON DAILY!',
        'prizeDetails-body2' : 'Before being declared an official winner, you must correctly answer, unaided, a mathematical skill-testing question.',

        // Participating Products
        'products' : 'PARTICIPATING PRODUCTS',
        'products.group1.title' : 'Cadbury',
        'products.group1' : `CADBURY CHOC VAR CP 16PK - 816G{br}
        CADBURY CHOC 18 TABL FORMAT CLUB - 956G{br}
        CADBURY EVDY MINI EGGS - 188G{br}
        CAD EVDY MICRO MINI EGGS - 90G{br}
        CADBURY EVDY MINI EGGS - 115G{br}
        MR BIG ORIGINAL 4PK - 240G{br}
        CARAMILK 4PK - 200G{br}
        CARAMILK 4PK - 200G GOLD{br}
        JERSEY MILK 4PK - 180G{br}
        CADBURY DAIRY MILK 4PK - 168G{br}
        WUNDERBAR 4PK - 232G{br}
        CAD DM FRUIT&NUT 4PK - 168G{br}
        CRISPY CRUNCH 4PK - 192G{br}
        CADBURY DAIRY MILK 4PK - 168G{br}
        CRUNCHIE 4PK - 176G{br}
        JERSEY MILK RRP 4PK - 180G{br}
        CARAMILK RRP 4PK - 4x50G{br}
        CARAMILK RRP 4PK - 4x50G{br}
        WUNDERBAR 4PK - 232G{br}
        CRUNCHIE 4PK - 176G{br}
        CAD DM HAZELNUT RRP - 200G{br}
        CAD DM MINI EGGS RRP - 200G{br}
        CADBURY DAIRY MILK RRP - 200G{br}
        CAD DM FRUIT&NUT RRP - 200G{br}
        CARAMILK - 50G{br}
        CARAMILK - 50G GOLD{br}
        CAD DAIRY MILK FRUIT&NUT - 42G{br}
        CADBURY DAIRY MILK - 42G {br}
        CRISPY CRUNCH - 48G{br}
        WUNDERBAR - 58G{br}
        CADBURY EVDY MINI EGGS - 33G{br}
        CRUNCHIE - 44G {br}
        CAD DAIRY MILK OREO - 38G{br}
        JERSEY MILK - 45G{br}
        CARAMILK SLTD CARAMEL - 50G{br}
        MR BIG ORIGINAL  - 60G{br}
        CADBURY FLAKE - 32G {br}
        CRUNCHIE KING SIZE  - 66G{br}
        MR BIG KING SIZE - 90G{br}
        WUNDERBAR KING SIZE - 90G{br}
        CARAMILK KING SIZE - 78G{br}
        CRISPY CRUNCH KING SIZE - 72G{br}
        CAD ASSORTED 20CT JUNIORS - 230G{br}
        CADBURY PLANT BAR SMOOTH RRP - 90G{br}
        CAD PLANT BAR SLTD CARML RRP - 90G{br}
        CARAMILK MINIS - 120G{br}
        MR BIG MINIS - 100G{br}
        WUNDERBAR MINIS - 120G{br}
        CAD DAIRY MILK BUTTONS - 120G{br}
        CARAMILK MINIS - 200G{br}
        MR BIG MINIS - 170G{br}
        WUNDERBAR MINIS - 200G{br}
        CAD DAIRY MILK BUTTONS 1- 90G{br}
        CADBURY DAIRY MILK MINI BARS - 152G{br}
        CAD DM HAZELNUT MINI BARS - 152G{br}
        CAD DM FRUIT&NUT MINI BARS - 152G{br}
        CAD DM MINI EGGS MINI BARS - 152G{br}
        CARAMILK MINI BARS - 147G{br}
        CADBURY DAIRY MILK OREO - 95G{br}
        CAD DAIRY MILK ALMOND - 100G{br}
        JERSEY MILK - 100G{br}
        CADBURY BURNT ALMOND - 100G{br}
        CADBURY PREMIUM DARK - 100G{br}
        CADBURY DAIRY MILK - 100G{br}
        CADBURY DAIRY MILK - 100G{br}
        CADBURY DAIRY MILK - 100G{br}
        CAD DAIRY MILK FRUIT&NUT - 100G{br}
        CAD DAIRY MILK HAZELNUT - 100G{br}
        CARAMILK - 100G{br}
        CARAMILK RRP - 100G{br}
        CARAMILK SLTD CARAMEL RRP - 100G{br}
        CAD DM CRISPY RICE RRP - 90G{br}
        CAD DM PNUTBTR&COOKIES RRP - 100G{br}
        CAD DM MINI EGGS RRP - 100G{br}
        CAD DM SMOOTH VANL CREME RRP - 95G{br}
        CAD DM CHOCOLATEY INDUL RRP - 95G{br}
        CAD DM CRMY SLTD CARAMEL RRP - 95G{br}
        CAD DM PEANUT BRITTLE RRP - 100G {br}
        CAD DM CANDY CANE RRP - 100G{br}
        CADBURY PREMIUM DARK RRP - 100G{br}
        CAD FRUIT&NUT DARK RRP - 100G{br}
        CAD DAIRY MILK ALMOND RRP - 100G{br}
        CAD DAIRY MILK ORANGE RRP - 100G{br}
        CAD DAIRY MILK MINT RRP - 100G{br}
        JERSEY MILK RRP - 100G{br}
        CADBURY DAIRY MILK RRP - 100G{br}
        CADBURY DAIRY MILK RRP - 100G{br}
        CAD DM FRUIT&NUT RRP - 100G{br}
        CAD DAIRY MILK HAZELNUT RRP - 100G{br}
        CAD DAIRY MILK TOFFEE RRP - 100G{br}
        CAD BURNT ALMOND RRP - 100G{br}
        CAD DM PRTZL PNUTBTR RRP - 100G{br}
        CAD DM OREO - 95G{br}
        CARAMILK SNACK SIZE - 10PK{br}
        MR BIG SNACK SIZE - 10PK{br}
        WUNDERBAR SNACK SIZE - 10PK{br}
        CRISPY CRUNCH SNACK SIZE - 10PK{br}
        DRP FULL CAD CHOC VAR CP - 18PK{br}
        DRP HLF CDM JRSY HMAID SMORE`,

        'products.group2.title' : 'Christie',
        'products.group2' : `CHR COOKIES CA! MINI INB - 7KG{br}
        CHR COOKIES OREO MINI INB - 7KG{br}
        CHR ARROWROOT CLUB PK - 1.4KG {br}
        CHR ARROWROOT - 350G{br}
        OREO GLUTEN FREE - 376G{br}
        OREO GLUTEN FREE - 342G{br}
        CHR OREO ORIG 4PK SC CLB - 2000G {br}
        OREO THINS ORIGINAL - 287G{br}
        OREO THINS GOLDEN - 287G{br}
        OREO THINS LEMON - 287G{br}
        OREO DARK CHOCOLATE - 303G{br}
        OREO FUDGE COVERED 224G{br}
        OREO THINS LATTE - 287G{br}
        OREO MINT FUDGE COVERED - 280G{br}
        OREO THINS DARK - 287G{br}
        OREO THINS ORIG - 257G{br}
        OREO THINS DARK - 257G{br}
        OREO ORIGINAL - 270G{br}
        OREO DOUBLE STUF - 261G{br}
        OREO GOLDEN - 270G{br}
        OREO GOLDEN DBL STUF - 261G{br}
        OREO BIRTHDAY - 261G{br}
        OREO MINT - 261G{br}
        OREO CINNAMON BUN - 261G{br}
        OREO DARK CHOCOLATE - 261G{br}
        OREO CARROT CAKE - 261G{br}
        CHIPS AHOY! MIDDLES CHOCHIP - 273G{br}
        CHR FUDGEE-O - 303G{br}
        CHR FUDGEE-O DOUBLE STUF - 303G{br}
        CHIPS AHOY! SFT CHUNKY ORIG 2 - 90G{br}
        NUTTER BUTTER FUDGE COVRD - 223G{br}
        CA! ORIGINAL - 258G{br}
        CA! CHUNKS - 251G{br}
        CA! CHUNKS TRIPCHOC - 251G{br}
        CA! CHEWY - 271G{br}
        CA! RAINBOW - 258G{br}
        OREO ORIGINAL - 500G{br}
        OREO GOLDEN - 500G{br}
        CHR FUDGEE-O - 500G{br}
        CHR FUDGEE-O - 500G{br}
        CHIPS AHOY! ORIGINAL 4 - 60G{br}
        CHIPS AHOY! ORIGINAL 4 - 60G OLYM{br}
        CHR OREO ORIG WIP - 500G{br}
        OREO DOUBLE STUF - 523G{br}
        CHIPS AHOY! CHEWY - 453G{br}
        CHIPS AHOY! RAINBOW - 457G{br}
        OREO THE MOST STUF - 379G{br}
        OREO MEGA STUF - 374G{br}
        OREO TIRAMISU - 432G{br}
        NUTTER BUTTER PNTBTR SDWCH - 453G{br}
        OREO CARAMEL COCONUT - 482G{br}
        OREO BIRTHDAY - 482G{br}
        CA! MINI EGGS 4 - 60G{br}
        CA! CARAMILK - 453G{br}
        OREO CHOC PNUT BTR PIE - 482G{br}
        OREO SNICKERDOODLE CINN - 345G{br}
        OREO NEAPOLITAN - 374G{br}
        OREO CHOC CONFETTI CAKE - 345G{br}
        OREO THE MOST OREO - 379G{br}
        OREO ORIG - 18PK 396G{br}
        EPK OREO VAR - 1.30KG{br}
        EPK MINI OREO CA! TG VAR - 1.35KG{br}
        EPK OREO RBS NB PIR FMLY - 1.41KG{br}
        EPK OREO CRSPS CARMLK SPK - 1.29KG{br}
        EPK OREO CA! FMLY VAR - 1.91KG{br}
        CHR FIG NEWTONS - 283G{br}
        CHR MINI OREO FUN TREATS - 450G{br}
        CHIPS AHOY! MINI FT - 450G{br}
        PEEK FREANS SOCIAL TEA - 525G{br}
        NUTTER BUTTER PB SGL SERVE - 56G{br}
        OREO ORIG 4PK - 45G {br}
        CA! ORIG 4PK - 57G{br}
        FULL PL OREO ORIG 2PK CLB - 880G{br}
        OREO PARTY PACK - 723G{br}
        CRISPERS SALT&VINEGAR RRP - 50G{br}
        CRISPERS ALL DRESSED RRP - 50G{br}
        CRISPERS BBQ RRP - 50G{br}
        CRISPERS SALT&VINEGAR BULK - 50G{br}
        CRISPERS BBQ BULK - 50G{br}
        CRISPERS ALL DRESSED BULK - 50G{br}
        CRISPERS CHEDDAR - 145G{br}
        CRISPERS DILL PICKLE - 145G{br}
        CRISPERS BBQ - 145G{br}
        CRISPERS RANCH - 145G{br}
        CRISPERS SALT&VINEGAR - 145G{br}
        CRISPERS ALL DRESSED - 145G{br}
        CRISPERS ALL DRESSED - 145G{br}
        CRISPERS KETCHUP - 145G{br}
        CRISPERS BITS & BITES ORIG - 145G{br}
        CRISPERS BITS & BITES CHEESE - 145G{br}
        CRISPERS BITS & BITES BBQ - 145G{br}
        CRISPERS JALAPENO - 145G{br}
        CRISPERS SALT&VINEGAR - 240G{br}
        CRISPERS BBQ - 240G{br}
        CRISPERS ALL DRESSED - 240G`,

        'products.group3.title' : 'Trident',
        'products.group3' : `DENTYNE ICE ASSTD 24PK - 288PC{br}
        TRID SLB FRESHMINT CLB - 14PC{br}
        TRID SLB SPEARMINT CLB - 14PC{br}
        TRID LAYERS STWBYCIT CLB - 14PC{br}
        TRIDENT ASSTD 24PK - 258PC{br}
        DENTYNE ICE AVALANCHE BTLS - 60PC{br}
        TRIDENT SLB SPEARMINT BTLS - 50PC{br}
        DENT ICE ARCT BUBBLE BTLS - 60PC{br}
        TRID VIBES SPRMT RUSH BTLS - 40PC{br}
        TRID VIBES TROP BEAT BTLS - 40PC{br}
        TRID VIBES SR BRY TWST BTLS - 40PC{br}
        TRID VIBES SPK BLRBRY BTLS - 40PC{br}
        TRID VIBES SPK REDBRY BTLS - 40PC{br}
        DENT WHITE SPEARMINT BTLS - 60PC{br}
        TRID SPLSH STRAW KIWI BTLS - 40PC{br}
        DENTYNE FIRE CINNAMON BTLS - 60PC{br}
        DENTYNE ICE SPEARMINT BTLS - 60PC{br}
        DENTYNE ICE PPRMT BTLS - 60PC{br}
        DENT ICE AVALANCHE 4PK - 48PC{br}
        DENT ICE ARCT BUBBLE 4PK - 48PC{br}
        TRID WHITE PEPPERMINT 4PK - 48PC{br}
        DENTYNE ICE PEPPERMNT 4PK - 48PC{br}
        DENTYNE ICE SPEARMINT 4PK - 48PC{br}
        DENTYNE ICE INTENSE 4PK - 48PC {br}
        DENTYNE ICE ASSTD 4PK - 48PC{br}
        DENTYNE FIRE CINNAMON 4PK - 48PC{br}
        TRID SPLASH VAN MINT 3PK - 27PC{br}
        TRID SPLSH STRAW KIWI 3PK - 27PC{br}
        TRIDENT PEL PPRMT 4PK - 48PC{br}
        TRIDENT PEL SPEARMINT 4PK - 48PC{br}
        DENTYNE ICE PEPPERMINT - 12PC{br}
        DENTYNE ICE MIDNIGHT MNT - 12PC{br}
        DENTYNE ICE AVALANCHE - 12PC{br}
        DENTYNE ICE ARCT BUBBLE - 12PC{br}
        DENT ICE SUBZ GLCR MINT - 12PC{br}
        DENT ICE SUBZ ICEBG MNT - 12PC{br}
        DENTYNE WHITE SPEARMINT - 12PC{br}
        DENTYNE FIRE CINNAMON - 12PC{br}
        DENTYNE ICE INTENSE - 12PC{br}
        DENTYNE ICE PEPPERMINT - 12PC{br}
        DENTYNE ICE SHIVER - 12PC{br}
        DENTYNE ICE SPEARMINT - 12PC{br}
        TRIDENT PEL SPEARMINT - 12PC{br}
        TRIDENT PEL PEPPERMINT - 12PC{br}
        TRIDENT SPLASH VAN MINT - 9PC{br}
        TRIDENT SPLASH STRAW KIWI - 9PC{br}
        DENTYNE FIRE CINNAMON 4PK - 48PC{br}
        DENTYNE ICE INTENSE 4PK - 48PC{br}
        DENTYNE ICE ASSTD 4PK - 48PC{br}
        TRID WHITE PEPPERMINT 4PK - 48PC{br}
        TRID PEL VERY BERRY 4PK - 48PC{br}
        DENTYNE ICE SPEARMINT 4PK - 48PC {br}
        DENTYNE ICE PEPPERMNT 4PK - 48PC{br}
        DENTYNE ICE ARCT BUBBLE 4PK - 48PC{br}
        DENTYNE ICE AVALANCHE 4PK - 48PC{br}
        TRID PEL PEPPERMINT 4PK - 48PC{br}
        DENT WHITE SPEARMINT 4PK - 48PC{br}
        TRIDENT SLB TROP TWST 3PK - 42PC{br}
        TRIDENT SLB BUBBLEGUM 3PK - 42PC{br}
        TRIDENT SLB WATERMELON 3PK - 42PC{br}
        TRIDENT SLB PEPPERMINT 3PK - 42PC{br}
        TRIDENT SLB SPEARMINT 3PK - 42PC{br}
        TRIDENT SLB SPEARMINT 3PK - 42PC{br}
        TRID LAYERS STRWBRY CIT 3PK - 42PC{br}
        TRIDENT SLB ORIGINAL - 14PC{br}
        TRIDENT SLB STRWBRY TWST - 14PC{br}
        TRIDENT SLB SPEARMINT - 14PC{br}
        TRIDENT SLB WTRMLN TWST - 14PC{br}
        TRIDENT SLB PEPPERMINT - 14PC{br}
        TRIDENT SLB TROP TWST - 14PC{br}
        TRIDENT SLB FRESHMINT - 14PC{br}
        TRIDENT SLB BUBBLEGUM - 14PC{br}
        TRID LAYERS STRWBRY CIT - 14PC{br}
        STRIDE SPEARMINT - 14PC`,
        

        //faq page
        'faq-heading-text1': 'While the ',
            'faq-heading-link': 'Official Rules',
        'faq-heading-text2': ' outline the complete details of this promotion, your questions may already be answered below.',

        'q1': 'Q. What is the date period for this promotion?',
        'a1': 'A. The Mondelez “You Could WIN a Game Studios Trip to L.A.” contest commences at 12:00 p.m. (noon) ET on October 9th, 2022 and ends at 11:59 p.m. ET on December 17, 2022.',
        
        'q2': 'Q. What are the prizes for this promotion?',
        'a2': 'A. There are two (2) Grand Prizes available to be won. Each Prize is worth $5,000 ARV and must be taken between March 1 - April 30, 2023. Plus, there are one hundred (100) Daily Prizes available to be won in relation to each Day during the Promotion Period.  Each Daily Prize consists of a one (1) month XBOX Game Pass Code (each, a “Code”). Each Daily Prize has an approximate retail value of $11.99 CAD.',
        
        'q2-b' : 'Q. How do I enter without purchasing a product?',
        'a2-b' : 'A. To enter without making a purchase, you may request a UPC by sending a unique and original 25-word essay about why you love playing video games along with your full name, age, and email address on an 8” x 11” piece of paper (the “Written Submission”) and mail it in an envelope with proper Canadian postage to “Snack On With Xbox 2022,” 6-6150 Highway 7, Suite #154, Woodbridge, ON, L4H 0R6 ',
        
        'q3': 'Q. How many times can I enter?',
        'a3': 'A. There is a limit of one (1) Eligible Entry per email address/person, per Day (regardless of method or combination of methods of entry).',
        
        'q4': 'Q. How many prizes could I win? ',
        'a4': 'A. There is a limit of one (1) Grand Prize per person. Plus, a limit of 1 Daily PC Game Pass prize per day, and limit of 2 Daily PC Game Pass prizes per person during the promotion.',

        'q5': 'Q. I have a valid UPC, but I am getting an error.',
        'a5': 'A. Please enter the code without any dashes or spaces and refer to our ',
        'a5a': 'participating products',
        'a5b': ' line up to make sure your purchase is eligible. If it is still not working, we are here to help! Please contact us at ',

        // 'q6': 'Q. I have a valid PIN code, but I’m getting an error.',
        // 'a6': 'A. Please enter the 12-digit alpha PIN code without any dashes or spaces. If it’s still not working, we’re here to help! Please contact us at ',
        
        'q6': 'Q. I received a message that I have already entered today, but I have not. What should I do?',
        'a6': 'A. Make sure you are visiting ',
            'a6-link': 'www.snackonwithxbox.ca',
        'a6-bottom': ' directly rather than a bookmarked page in your browser. Also, please try to clear cookies and cache on your internet browser.',

        'q7': 'Q. I am having technical issues. What should I do?',
        'a7': 'A. Sometimes it is a simple thing like updating your browser. Check to be sure that you have a modern version of Chrome, Internet Explorer, Firefox, or Safari. Also, you may want to check your browser preferences section to be sure JavaScript is enabled. Clearing your cache and cookies also helps in this instance.',

        'q8': 'Q. How will I know if I’m a potential Daily Prize Winner?',
        'a8': 'A. .You will be advised on the screen if you have been declared a potential Daily prize winner. Each potential winner will have to correctly answer a skill testing question on the screen to be declared an official winner.',
        
        'q9': 'Q. I am having trouble receiving my PC Game Pass Email, what should I do?',
        'a9': 'Please check your email and spam filters or contact us at ',
        
        'q10': 'Q. I am having trouble getting my PC Game Pass to work? What should I do?',
        'a10': 'For details on how to redeem a Code, visit: ',
        'a10-link': 'https://support.xbox.com/en-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        'q11': 'Q. I have recently updated my email address. Can I change my registration information for this promotion?',
        'a11': 'A. We apologize for any inconvenience, but due to the number of entries we receive, we are unable to change registration information. If you are entered into the Grand Prize Draw during the promotional period and your contact information does change, contact ',
        'a11-2' : ' and provide your updated information.  {br}{br} Please note: If your contact information changes during the promotional period, we cannot guarantee that we will be able to accommodate your request. {br}{br} Still have a question? Please contact us at ',
        'a11-3' : ' Please note: a response may take up to three (3) business days.',

        //Rules page
        'rules-page-heading': '“SNACK ON WITH XBOX” PROMOTION OFFICIAL RULES',
    }
}