import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

//language file
import { translate, translatedlink } from '../../i18n/translate';

import { getContestState } from '../../util';

//css for faq page
import '../../styles/pages/_faq.scss';

class Faq extends Component{

    componentDidMount(){
        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading">
                                        <h1>FAQ</h1>
                                    </div>
                                    <div className="page-body faq">
                                        <p>
                                            {translate('faq-heading-text1')}  
                                            <NavLink exact to="/rules">{translate('faq-heading-link')}</NavLink>
                                            {translate('faq-heading-text2')}
                                        </p>
                                        <div className="qa">
                                            <h3 className="q">{translate('q1')}</h3>
                                            <p className="a">{translate('a1')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q2')}</h3>
                                            <p className="a">
                                                {translate('a2')}
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q2-b')}</h3>
                                            <p className="a">
                                                {translate('a2-b',{u: chunks => <u>{chunks}</u>,b: chunks => <b>{chunks}</b>})}
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q3')}</h3>
                                            <p className="a">{translate('a3')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q4')}</h3>
                                            <p className="a">{translate('a4')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q5')}</h3>
                                            <p className="a">
                                                {translate('a5')}
                                                <NavLink exact to="/products">{translate('a5a')}</NavLink>
                                                {translate('a5b')}
                                                {translatedlink('contact-email')}
                                                .
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q6')}</h3>
                                            <p className="a">{translate('a6')} <NavLink exact to="/">{translate('a6-link')}</NavLink> {translate('a6-bottom')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q7')}</h3>
                                            <p className="a">{translate('a7')}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q8')}</h3>
                                            <p className="a">{translate('a8',{br: <br />})}</p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q9')}</h3>
                                            <p className="a">
                                                {translate('a9')}
                                                {translatedlink('contact-email')}.
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q10')}</h3>
                                            <p className="a">
                                                {translate('a10')}
                                                {translatedlink('a10-link')}.
                                            </p>
                                        </div>
                                        <div className="qa">
                                            <h3 className="q">{translate('q11')}</h3>
                                            <p className="a">
                                                {translate('a11', {br: <br/>})}
                                                {translatedlink('contact-email')}
                                                {translate('a11-2', {br: <br/>})}
                                                {translatedlink('contact-email')}.
                                                {translate('a11-3', {br: <br/>})}
                                            </p>
                                        </div>

                                        {/* <div className="qa">
                                            <p className="a">{translate('faq-footer-text')}</p>
                                        </div> */}
                                        
                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                        </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Faq;