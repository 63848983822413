import React, { Component } from 'react';
import { TextField } from '@material-ui/core';

//language file
import { translate } from '../../i18n/translate';



class STQForm extends Component{

    state = {
        buttonHover : false,
        val: ''
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    componentDidMount(){
        // const form = this.form;

        // gsap.from(form, .6, {
        //     opacity:1,
        //     x: -300,
        //     ease: Power3.easeOut
        // })
    }

    checkAnswer = (e) => {
        e.preventDefault();
        console.log('check answer',this.form, this.state, this);
        if (this.state.val==='25'){
            this.props.nextCorrect();
        } else {
            this.props.nextIncorrect();
        }
    }

    handleChange = (e) => {
        this.setState({ val: e.target.value});
    }

    render(){

        const { val } = this.state;

        return(
            <form onSubmit={this.checkAnswer} ref={el => this.form = el}>
                <h3>{translate('winningPage.question')}</h3>
                <TextField variant="filled" required label={translate('winningPage.answer')} onChange={this.handleChange} defaultValue={val} />
                <button type="submit" className="pin-form__submit-btn green-btn" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onClick={this.checkAnswer} disabled={val !== "" ? false : true} >
                    {translate('winningPage.cta')}
                </button>
            </form>
        )
    }
}

export default STQForm;