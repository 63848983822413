import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { TextField } from '@material-ui/core';
import ReactGA from 'react-ga';

//language file
import { translate } from '../../i18n/translate';
import { LOCALES } from '../../i18n';


//buttons
// import pinFormPopup from '../../images/question.png';
import pinFormPopupImage from '../../images/barcode.en.png';
import pinFormPopupImagefr from '../../images/barcode.fr.png';
// import closeBtn from '../../images/close black.png';
import { NavLink } from 'react-router-dom';

class PinForm extends Component{

    state = {
        // buttonHover : false,
        // popupVisible: false,
        pinError: ''
    }

    //Handle Mouse over event for enter now button
    // mouseEnter = () =>{
    //     this.setState(prevState =>{
    //         return {buttonHover: !prevState.buttonHover}
    //     })
    // }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    // mouseLeave = () =>{
    //     this.setState(prevState =>{
    //         return {buttonHover: !prevState.buttonHover}
    //     })
    // }

    /*
    //pin form help popup
    popupClickHandle = () => {
        this.setState({
            popupVisible: true
        })
    }

    //popup close button click
    popupCloseButtonHandle = () => {
        this.setState({
            popupVisible: false
        })
    } */

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        const form = this.form;

        gsap.from(form, .6, {
            opacity:1,
            x: -300,
            ease: Power3.easeOut
        })
    }

    render(){

        const { values, nextStep, handleChange, inputPinClickHandler, errorMessage, inputKeypressHandler, lang } = this.props;

        return(
            <Fragment> 
                <div className="main-wrapper">
                    <div className="container pin-form__container">
                        <p className="steps">{translate('step1')}</p>
                        <h1 className="form__heading pin-form__heading">{translate('enterPin')}</h1>
                        <img src={lang===LOCALES.ENGLISH?pinFormPopupImage:pinFormPopupImagefr} alt="Pin form popup figure"/>
                        <p className="form__description pin-form__description">{translate('enterKeyDescription')}<NavLink to="/products">{translate('enterKeyLink')}</NavLink><small>{translate('enterKeySmall')}</small></p>
                        <div className="mui-container pin-form" ref={el => this.form = el}>
                            <form onSubmit={nextStep}>
 
                                {/* <div className={this.state.popupVisible ? 'pinFormPopup visible' : 'pinFormPopup'}>
                                    <div className="pinFormPopup--container">
                                        <div className="pinFormPopup__closeButton" onClick={this.popupCloseButtonHandle}><img src={closeBtn} alt="close button"/></div>
                                        <div className="pinFormPupup__content">
                                            <img src={pinFormPopupImage} alt="Pin form popup figure"/>
                                            <p>{translate('popupBodytext')}<NavLink to="/products">{translate('popupBodylink')}</NavLink></p>
                                        </div>
                                    </div>
                                </div>*/}

                                <TextField className={errorMessage ? 'error' : ''} variant="filled" required label={translate('pin')} defaultValue={values.pin} onChange={handleChange('pin')} onClick={inputPinClickHandler} helperText={errorMessage} onKeyPress={inputKeypressHandler} />
                                {/* <div className="pinFormPopupButton" onClick={this.popupClickHandle}><img src={pinFormPopup} alt="UPC help"/></div> */}
                                <button type="submit" className="pin-form__submit-btn green-btn" onClick={nextStep} disabled={values.pin !== "" ? false : true} >
                                    <p>{translate('nextStep')}</p>
                                </button>
                            </form>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default PinForm;