import React, { Component, Fragment } from 'react';
import InjectIntl from 'react-intl-inject';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
// import {InlineShareButtons} from 'sharethis-reactjs';


// import packshot from '../../../src/images/packages.png';
// import packshotfr from '../../../src/images/packages.png';

//images
import twitterIcon from '../../images/twitter.png';
import facebookIcon from '../../images/facebook.png';
import downloadBtn from '../../images/download.png';



//social share and :og tags
// import LosingKeySocialShare from '../LosingKeySocialShare';

//css
import '../../styles/pages/_you-lose.scss';
import { translate } from '../../i18n/translate';

class YouLose extends Component{
    
    state = {
        buttonHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {buttonHover: !prevState.buttonHover}
        })
    }

    //component did mount
    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        // language cookie setting for IG image
        // const langSelected = Cookie.get('lang-selected');
        // if(!langSelected) {
        //     Cookie.set('lang-preferance', 'en-US')
        // }

        const enterNowButton = document.querySelector('.green-btn')
        enterNowButton.addEventListener('click', (e) =>{
            e.preventDefault();
            window.location.reload()
        })

        const menuEnterContest = document.querySelector('#menu-enterContest')
        menuEnterContest.addEventListener('click', (e) =>{
            e.preventDefault();
            window.location.reload()
        })

        const igDownloadBtn = document.querySelector('#ig-download');
        igDownloadBtn.addEventListener('click', (e)=>{
            ReactGA.event({
                category: 'Download',
                action: 'Instagram'
              });
        })
    }

    //facebook share new window
    fbShare = () => {
        ReactGA.event({
            category: 'ShareThis',
            action: 'facebook'
          });

        const langPreferance = this.props.lang;
        const siteDomain = langPreferance === 'fr-CA' ? 'rechargetescollationsavecxbox.ca' : 'snackonwithxbox.ca'; 
        const shareLink = `https://www.facebook.com/sharer/sharer.php?u=https://${siteDomain}`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    //twitter share new window // not used.
    twShare = () => {
        ReactGA.event({
            category: 'ShareThis',
            action: 'twitter'
          });

        const langPreferance = this.props.lang;
        const siteDomain = langPreferance === 'fr-CA' ? 'rechargetescollationsavecxbox.ca' : 'snackonwithxbox.ca'; 
        const shareLink = langPreferance === 'fr-CA' ? `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}&text=Vous%20pourriez%20GAGNER*%20un%20VOYAGE%20XBOX%20GAME%20STUDIOS%20À%20LOS%20ANGELES!%20DE%20PLUS,%20100%20PC%20GAME%20PASS%20chaque%20jour%20en%20jeu.` : `https://twitter.com/intent/tweet?url=https%3A%2F%2F${siteDomain}&text=You%20could%20WIN%20AN%20XBOX%20GAME%20STUDIOS%20TRIP%20TO%20L.A.!%20PLUS,%20There%20are%20100%20DAILY%20PC%20GAME%20PASS%20PRIZES.`
        window.open(shareLink, "Social", "width=500,height=500");
    }

    render(){

        // const remainingKeys = 5; 
        // const currentPage = window.location.href;
        // const siteDomain = 'snackonwithxbox.ca'; 
        // const langPreferance = this.props.lang;

        return(
            <Fragment>
                <div className="main-wrapper you-lose">
                    {/* <LosingKeySocialShare remainingKeys={remainingKeys} /> */}
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="you-win">
                                        <h1>{translate('tryAgain')}</h1>
                                        <p>{translate('tryAgain.body', {br: <br />})}</p>
                                        
                                        <NavLink exact to="/enter-contest" className="green-btn">
                                            {translate('tryAgain.btn')}
                                        </NavLink>
                                        
                                        <h2>{translate('tryAgain.heading2')}</h2>
                                        <p>{translate('tryAgain.body2')}</p>
                                        <div className="social-container">
                                            <button onClick={this.fbShare} style={{background: 'transparent', border: 'none'}}><img src={facebookIcon} alt="Caramilk faceook"/></button>
                                            <button onClick={this.twShare} style={{background: 'transparent', border: 'none'}}><img src={twitterIcon} alt="caramilk twitter"/></button>
                                        </div>
                                        <p>{translate('tryAgain.body3')}</p>
                                        <a href={ `/xbox_instagram.png` } target="_blank" rel="noopener noreferrer" download id="ig-download">
                                            <InjectIntl>
                                                {({ intl }) => (
                                                    <img className="download-btn" src={downloadBtn} alt={intl.formatMessage({ id: 'download' })} />
                                                )}
                                            </InjectIntl>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> 
            </Fragment>
        );
    }
}

export default YouLose;