import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { TweenMax, Power3 } from 'gsap';
// import InjectIntl from 'react-intl-inject';
import ReactGA from 'react-ga';

//import css
import '../../styles/pages/_prize-details.scss';

//language file
import { translate } from '../../i18n/translate';

import { getContestState } from '../../util';

import prizeImage1 from '../../images/obsidian_prize.png';
import prizeImage2 from '../../images/gamepass.jpg';

class PrizeDetails extends Component{

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { heading, prize1, prize1desc, prize2, prize2desc } = this;
        TweenMax.staggerFrom([heading, prize1, prize1desc, prize2, prize2desc], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.2)
    }

    render(){
        const contestState = getContestState();
        return(
            <Fragment>
                <div className="main-wrapper prize-details">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="page-heading">
                                        <h1 ref={el =>{this.heading = el}}>{translate('prizeDetails')}</h1>
                                    </div>
                                    <div className="row prize-details__text-section grand-prize">
                                        <div className="col-md-6 img">
                                            <img src={prizeImage1} ref={el => {this.prize1 = el}} alt="Grand Prize" className="img-fluid overflow" />
                                        </div>
                                        <div className="col-md-6" ref={el => {this.prize1desc = el}}>
                                            <h2>{translate('prizeDetails-heading1')}</h2>
                                            <h4>{translate('prizeDetails-subheading1')}</h4>
                                            <p>{translate('prizeDetails-body1', {br : <br />})}</p>
                                        </div>
                                    </div>
                                    <div className="row prize-details__text-section">
                                        <div className="col-md-6" ref={el => {this.prize2desc = el}}>
                                            <h2>{translate('prizeDetails-heading2')}</h2>
                                            <h4>{translate('prizeDetails-subheading2')}</h4>
                                            <p>{translate('prizeDetails-body2', {br : <br />})}</p>
                                        </div>
                                        <div className="col-md-6 img">
                                            <img src={prizeImage2} ref={el => {this.prize2 = el}} alt="PC Game Passes" className="img-fluid" />
                                        </div>
                                    </div>
                                    { contestState===0 && <div className="text-center">
                                            <NavLink exact to="/enter-contest" className="enter-btn">
                                                {translate('enterNow')}
                                            </NavLink> 
                                        </div> }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default PrizeDetails;