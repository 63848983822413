import React, { Component, Fragment } from 'react';
import { TweenMax, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import Playa from '../Playa';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';

import xbox from '../../images/Xbox_final.png';
import obsidian from '../../images/obsidian.png';

class ContestClosed extends Component {

    state = {
        isHover : false
    }

    //Handle Mouse over event for enter now button
    mouseEnter = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }

    //HANDLE MOUSE LEAVE ON THE ENTER NOW BUTTON
    mouseLeave = () =>{
        this.setState(prevState =>{
            return {isHover: !prevState.isHover}
        })
    }


    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        TweenMax.staggerFrom([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut
        },.1)
    }

    componentWillUnmount(){
        
    }

    render(){
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container home">
                        <div className="row">
                            <div className="col-12">
                                <Playa />
                            </div>
                            <div className="col home-text-container text-center"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <h1 className="unlock-the-secret" ref={el => {this.headingOne = el}}>{translate('homepage.unlock')}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingTwo = el}}>{translate('homepage.prize',{sup: chunks => <sup>{chunks}</sup>,small: chunks => <small>{chunks}</small>})}</h1>
                                    <h2 className="plus-cash" ref={el => {this.headingFour = el}}>{translate('homepage.pluscash')}</h2>
                                    <div className="logos">
                                        <span><img src={xbox} alt="XBOX" /></span>
                                        <span><img src={obsidian} alt="Obsidian" /></span>
                                    </div>
                                </div>
                                <p className="home-body-text">{translate('contestClosedHead', {br: <br/>})}</p>
                                <p className="home-body-text">{translate('contestClosedText', {br: <br/>})}</p>
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                                <small>{translate('legal',{link:<NavLink exact to="/">{translate('legal.link')}</NavLink>})}</small>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;