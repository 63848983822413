import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import { LOCALES } from '../../i18n'; //language files

import Playa from '../Playa';

import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';
import packshot from '../../../src/images/packages.png';
import step2 from '../../../src/images/barcode.en.png';
import step2fr from '../../../src/images/barcode.fr.png';
import step3 from '../../../src/images/obsidian_prize.png';

import xbox from '../../images/Xbox_final.png';
import obsidian from '../../images/obsidian.png';

class Homepage extends Component {

    state = {
        isHover : false
    }

    componentDidMount(){

        //initiate google analytics
        ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        gsap.from([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .1
        },.1)
    }

    componentWillUnmount(){
        
    }

    render(){
        const langPreferance = this.props.lang;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container home">
                        <div className="row">
                            <div className="col-12">
                                <Playa />
                            </div>
                            <div className="col home-text-container text-center"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <h1 className="unlock-the-secret" ref={el => {this.headingOne = el}}>{translate('homepage.unlock')}</h1>
                                    <h1 className="prize-money" ref={el => {this.headingTwo = el}}>{translate('homepage.prize',{sup: chunks => <sup>{chunks}</sup>,small: chunks => <small>{chunks}</small>})}</h1>
                                    <h2 className="plus-cash" ref={el => {this.headingFour = el}}>{translate('homepage.pluscash')}</h2>
                                    <div className="logos">
                                        <span><img src={xbox} alt="XBOX" /></span>
                                        <span><img src={obsidian} alt="Obsidian" /></span>
                                    </div>
                                </div>
                                <NavLink exact className="enter-btn" activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                    {translate('enterNow')}
                                </NavLink>
                                <div className="learn-more-container">
                                    <a href="#howtoenter">
                                        {translate('homepage.cta')}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="howtoenter">
                            <div className="col-md-12">
                                <h2>{translate('homepage.howtoenter')}</h2>
                            </div>
                            <div className="col-md-4">
                                <img src={packshot} className="packshot img-fluid overflow" alt="Mondelez packs" />
                                <h3>{translate('homepage.step1head')}</h3>
                                <p>{translate('homepage.step1')}</p>
                            </div>
                            <div className="col-md-4">
                                <img src={langPreferance===LOCALES.ENGLISH?step2:step2fr} className="stepimage img-fluid" alt="look for upc code" />
                                <h3>{translate('homepage.step2head')}</h3>
                                <p>{translate('homepage.step2', {br: <br />,small:chunks=><small>{chunks}</small>})}</p>
                            </div>
                            <div className="col-md-4">
                                <img src={step3} className="stepimage img-fluid overflow" alt="enter a upc code" />
                                <h3>{translate('homepage.step3head')}</h3>
                                <p>{translate('homepage.step3')}</p>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <NavLink exact className="enter-btn" activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                    {translate('enterNow')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-12 disclaimer">
                                <small>{translate('legal',{link:<NavLink exact to="/">{translate('legal.link')}</NavLink>})}</small>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default Homepage;