import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'Concours le Secret de la Caramilk',
        'close': 'Proche',

        //header

        //nav
        'menu.item.home' : 'ACCUEIL',
        'menu.item.winner' : 'ANCIENS GAGNANTS',
        'menu.item.1': 'PARTICIPEZ AU CONCOURS',
        'menu.item.2': 'DÉTAILS DU PRIX',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RÈGLEMENT',
        'menu.item.5': 'PRODUITS PARTICIPANTS',
        
        'contact-email': 'info@rechargetescollationavecxboxconcours.ca',

        //footer
        'footer.link1' : 'Règlement',
        'footer.link2' : 'Politique de confidentialité',
        'footer.link3' : 'Modalités',
        'footer.link4' : 'Politique d’accessibilité',
        'footer.link5' : 'Communiquez avec nous',

        //homapge
        'homepage.gameon' : 'C’EST PARTI !',
        'homepage.unlock': 'Vous pourriez',
        'homepage.prize' : 'GAGNER<sup>*</sup> <small>un voyage</small> XBOX GAMES STUDIOS à L.A.',
        'homepage.pluscash': '100 PRIX PC GAME PASS À GAGNER EN PLUS',
        'homepage.cta' : 'FAITES DÉFILER POUR SAVOIR COMMENT PARTICIPER',
        'homepage.howtoenter' : 'COMMENT PARTICIPER',
        'homepage.step1head' : 'ÉTAPE 1',
        'homepage.step1' : 'Achetez un produit Mondelez Canada participant',
        'homepage.step2head' : 'ÉTAPE 2',
        'homepage.step2' : 'Recherchez le code UPC à 11 chiffres du produit ** figurant sur l’emballage de votre produit Mondelez participant. <small>**Omettez le premier chiffre à gauche du code.</small>',
        'homepage.step3head' : 'ÉTAPE 3',
        'homepage.step3' : 'Entrez UPC pour courir la chance de gagner',

        'contestClosedHead' : 'CONCOURS TERMINÉ',
        'contestClosedText' : 'Merci de participer! Le concours Mondelez Canada « Vous pourriez gagner un voyage XBOX STUDIOS À LOS ANGELES » est terminé.',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',

        'enterNow' : 'PARTICIPEZ MAINTENANT',

        'legal' : '*AUCUN ACHAT REQUIS. Ouvert aux résidents du Canada (ayant atteint l’âge de majorité).  Fin le 17 déc. 2022 (23 h 59 min 59 s HE).  Participation en ligne et règlement complet sur le site {link} 100 Prix PC Game Pass par jour [VDA: 11,99 $ CA chacun // doit être utilisé avant le 31 jan. 2023 // carte de crédit requise pour utiliser PC Game Pass, qui nécessite un abonnement continu (annuler dans les 30 jours pour éviter les frais) // limite de 1 Prix PC Game Pass quotidien par jour // limite de 2 Prix PC Game Pass quotidiens pendant la promotion].  2 Grands prix [VDA : 5000 $ CA chacun // limite d’un Grand prix par personne].  Le voyage doit être effectué entre le 1er mars et le 30 avril 2023. Les chances de gagner dépendent du nombre de participations admissibles. Question d’habileté requise. Les marques de commerce sont la propriété de leurs propriétaires respectifs. Xbox est une marque de commerce déposée de Microsoft. Microsoft n’est pas un commanditaire.',
        'legal.link' : 'www.RechargeTesCollationsAvecXbox.ca',

        'comingsoon' : 'RECHERCHEZ NOS PRÉSENTORS DE VENTE AU DÉTAIL À PARTIR DU 9{nbsp}OCTOBRE{nbsp}2022',

        //enter contest page
        'step1': 'Étape 1',
        'enterPin': 'SAISIR L’UPC',
        'enterKeyDescription': 'Saisissez les 11 chiffres du code UPC du produit ** figurant sur ',
        'enterKeyLink': 'l’emballage de votre produit Mondelez participant.',
        'enterKeySmall': '**Omettez le premier chiffre à gauche du code.',
        'popupBodytext' : 'Recherchez le code UPC au dos de votre achat d’un ',
        'popupBodylink' : 'produit participant.',
        'pin': 'UPC',
        'nextStep': 'Étape Suivante',

        'step2': 'Étape 2',
        'enterInfo': 'INFORMATIONS SUR LA PARTICIPATION',
        'enterInfoDescription': 'Fournissez vos informations de participation au concours pour participer et voir si vous avez gagné un prix quotidien.',
        'firstName': 'Prénom',
        'lastName': 'Nom',
        'emailAddress': 'Adresse électronique',
        'infoForm.age': 'Je confirme avoir atteint l’âge de la majorité dans ma province ou mon territoire de résidence*',
        'infoForm.rules' : 'J’ai lu le ',
            'infoForm.rules.link1' : 'Règlement du concours',
            'infoForm.rules2' : ' et la ',
            'infoForm.rules.link2' : 'Politique de confidentialité de Mondelēz',
            'infoForm.rules3' : ' et je confirme que je m’y conformerai',
        'infoForm.optin' : 'Je confirme mon consentement à ce que Mondelez Canada Inc. stocke, partage et utilise mes renseignements personnels soumis, à des fins d’administration du concours (y compris pour me notifier si je suis sélectionné comme gagnant admissible d’un prix), conformément au règlement du concours et à la politique de confidentialité de Mondelez*',
        'requiredFields' : '*Champs requis',
        'submit': 'Soumettre',

        //winning page
        'congratulation' : 'VOUS Y ÊTES PRESQUE !',
        'winningPage.heading' : 'VOUS ÊTES UN GAGNANT POTENTIEL D’UN XBOX PC GAME PASS !',
        'winningPage.body' : 'Avant d’être déclaré un gagnant officiel, vous devez répondre correctement, sans aide, à la question d’habileté mathématique ci-dessous.',
        'winningPage.question' : '(6X6) – 16, puis ajouter 5 =',
        'winningPage.answer' : "Your Answer",
        'winningPage.cta' : 'SOUMETTRE',
        'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'FÉLICITATIONS !',
        'winningPage.heading2' : 'VOUS ÊTES LE GAGNANT D’UN XBOX PC GAME PASS !',
        'winningPage.body2' : 'Vous recevrez le PC Game Pass à l’adresse électronique fournie dans un délai de 1 à 2 jours. {br}{br}De plus, vous avez été inscrit au tirage au sort du Grand prix !{br}{br}Si vous ne recevez pas l’e-mail du PC Game Pass d’ici 1 à 2 jours, veuillez vérifier votre courrier électronique et vos filtres anti-spam ou contactez-nous à ',
        'winningPage.body2a' : '.{br}{br}Remarque : Une carte de crédit est requise pour utiliser PC Game Pass, qui nécessite un abonnement permanent (annuler dans les 30 jours pour éviter les frais).',

        'winningPage.heading3' : 'DÉSOLÉ, MAUVAISE RÉPONSE.',
        'winningPage.body3' : 'Veuillez réessayer avec un autre code UPC.',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Télécharger',

        //losing page
        'tryAgain' : 'MERCI DE PARTICIPER AU CONCOURS',
        'tryAgain.body' : 'Vous avez été inscrit avec succès au prochain tirage au sort pour avoir une chance de GAGNER UN VOYAGE AUX STUDIOS DES JEUX XBOX À LOS ANGELES{br}{br}Vous n’êtes pas le gagnant d’aujourd’hui, mais il y a encore des prix PC Game Pass à gagner.{br}{br}Entrez un autre UPC trouvé sur les emballages participants pour avoir une autre chance de gagner*.',
        'tryAgain.btn' : 'ESSAYEZ DE NOUVEAU', 
        'tryAgain.heading2' : 'PARTAGEZ LE PLAISIR',
        'tryAgain.body2' : 'Faites participer vos amis à l’action ! Partagez le concours avec vos amis et faites-leur savoir qu’il y a encore des prix XBOX à gagner ! ',
        'tryAgain.body3' : 'Vous voulez partager sur Instagram? Cliquez sur le bouton de téléchargement ci-dessous et obtenez une image que vous pourrez télécharger sur votre compte Instagram.',

        //Prize Details
        'prizeDetails' : 'DÉTAILS DU PRIX',

        'prizeDetails-heading1' : 'GRAND PRIX',
        'prizeDetails-subheading1' : 'Découvrez la magie d’un studio de jeux primé !',
        'prizeDetails-body1' : 'Le voyage pour deux personnes aux STUDIOS OBSIDIAN à Los Angeles vous permettra, à vous et à un ami, d’être accueillis comme des VIP, de visiter les studios, de jouer aux derniers jeux Obsidian et de recevoir des sacs cadeaux à votre départ. Le prix comprend également une manette sans fil XBOX Elite Series 2 et un abonnement d’un an à PC Game Pass.',

        'prizeDetails-heading2' : 'PRIX QUOTIDIENS',
        'prizeDetails-subheading2' : '100 prix XBOX PC Game Pass à GAGNER CHAQUE JOUR!',
        'prizeDetails-body2' : 'Avant d’être déclaré un gagnant officiel, vous devez répondre correctement, sans aide, à la question d’habileté mathématique.',

        

        // Participating Products
        'products' : 'PRODUITS PARTICIPANTS',
        'products.group1.title' : 'Cadbury',
        'products.group1' : `CADBURY CHOC VAR CP 16PK - 816G{br}
        CADBURY CHOC 18 TABL FORMAT CLUB - 956G{br}
        CADBURY EVDY MINI EGGS - 188G{br}
        CAD EVDY MICRO MINI EGGS - 90G{br}
        CADBURY EVDY MINI EGGS - 115G{br}
        MR BIG ORIGINAL 4PK - 240G{br}
        CARAMILK 4PK - 200G{br}
        CARAMILK 4PK - 200G GOLD{br}
        JERSEY MILK 4PK - 180G{br}
        CADBURY DAIRY MILK 4PK - 168G{br}
        WUNDERBAR 4PK - 232G{br}
        CAD DM FRUIT&NUT 4PK - 168G{br}
        CRISPY CRUNCH 4PK - 192G{br}
        CADBURY DAIRY MILK 4PK - 168G{br}
        CRUNCHIE 4PK - 176G{br}
        JERSEY MILK RRP 4PK - 180G{br}
        CARAMILK RRP 4PK - 4x50G{br}
        CARAMILK RRP 4PK - 4x50G{br}
        WUNDERBAR 4PK - 232G{br}
        CRUNCHIE 4PK - 176G{br}
        CAD DM HAZELNUT RRP - 200G{br}
        CAD DM MINI EGGS RRP - 200G{br}
        CADBURY DAIRY MILK RRP - 200G{br}
        CAD DM FRUIT&NUT RRP - 200G{br}
        CARAMILK - 50G{br}
        CARAMILK - 50G GOLD{br}
        CAD DAIRY MILK FRUIT&NUT - 42G{br}
        CADBURY DAIRY MILK - 42G {br}
        CRISPY CRUNCH - 48G{br}
        WUNDERBAR - 58G{br}
        CADBURY EVDY MINI EGGS - 33G{br}
        CRUNCHIE - 44G {br}
        CAD DAIRY MILK OREO - 38G{br}
        JERSEY MILK - 45G{br}
        CARAMILK SLTD CARAMEL - 50G{br}
        MR BIG ORIGINAL  - 60G{br}
        CADBURY FLAKE - 32G {br}
        CRUNCHIE KING SIZE  - 66G{br}
        MR BIG KING SIZE - 90G{br}
        WUNDERBAR KING SIZE - 90G{br}
        CARAMILK KING SIZE - 78G{br}
        CRISPY CRUNCH KING SIZE - 72G{br}
        CAD ASSORTED 20CT JUNIORS - 230G{br}
        CADBURY PLANT BAR SMOOTH RRP - 90G{br}
        CAD PLANT BAR SLTD CARML RRP - 90G{br}
        CARAMILK MINIS - 120G{br}
        MR BIG MINIS - 100G{br}
        WUNDERBAR MINIS - 120G{br}
        CAD DAIRY MILK BUTTONS - 120G{br}
        CARAMILK MINIS - 200G{br}
        MR BIG MINIS - 170G{br}
        WUNDERBAR MINIS - 200G{br}
        CAD DAIRY MILK BUTTONS 1- 90G{br}
        CADBURY DAIRY MILK MINI BARS - 152G{br}
        CAD DM HAZELNUT MINI BARS - 152G{br}
        CAD DM FRUIT&NUT MINI BARS - 152G{br}
        CAD DM MINI EGGS MINI BARS - 152G{br}
        CARAMILK MINI BARS - 147G{br}
        CADBURY DAIRY MILK OREO - 95G{br}
        CAD DAIRY MILK ALMOND - 100G{br}
        JERSEY MILK - 100G{br}
        CADBURY BURNT ALMOND - 100G{br}
        CADBURY PREMIUM DARK - 100G{br}
        CADBURY DAIRY MILK - 100G{br}
        CADBURY DAIRY MILK - 100G{br}
        CADBURY DAIRY MILK - 100G{br}
        CAD DAIRY MILK FRUIT&NUT - 100G{br}
        CAD DAIRY MILK HAZELNUT - 100G{br}
        CARAMILK - 100G{br}
        CARAMILK RRP - 100G{br}
        CARAMILK SLTD CARAMEL RRP - 100G{br}
        CAD DM CRISPY RICE RRP - 90G{br}
        CAD DM PNUTBTR&COOKIES RRP - 100G{br}
        CAD DM MINI EGGS RRP - 100G{br}
        CAD DM SMOOTH VANL CREME RRP - 95G{br}
        CAD DM CHOCOLATEY INDUL RRP - 95G{br}
        CAD DM CRMY SLTD CARAMEL RRP - 95G{br}
        CAD DM PEANUT BRITTLE RRP - 100G {br}
        CAD DM CANDY CANE RRP - 100G{br}
        CADBURY PREMIUM DARK RRP - 100G{br}
        CAD FRUIT&NUT DARK RRP - 100G{br}
        CAD DAIRY MILK ALMOND RRP - 100G{br}
        CAD DAIRY MILK ORANGE RRP - 100G{br}
        CAD DAIRY MILK MINT RRP - 100G{br}
        JERSEY MILK RRP - 100G{br}
        CADBURY DAIRY MILK RRP - 100G{br}
        CADBURY DAIRY MILK RRP - 100G{br}
        CAD DM FRUIT&NUT RRP - 100G{br}
        CAD DAIRY MILK HAZELNUT RRP - 100G{br}
        CAD DAIRY MILK TOFFEE RRP - 100G{br}
        CAD BURNT ALMOND RRP - 100G{br}
        CAD DM PRTZL PNUTBTR RRP - 100G{br}
        CAD DM OREO - 95G{br}
        CARAMILK SNACK SIZE - 10PK{br}
        MR BIG SNACK SIZE - 10PK{br}
        WUNDERBAR SNACK SIZE - 10PK{br}
        CRISPY CRUNCH SNACK SIZE - 10PK{br}
        DRP FULL CAD CHOC VAR CP - 18PK{br}
        DRP HLF CDM JRSY HMAID SMORE`,

        'products.group2.title' : 'Christie',
        'products.group2' : `CHR COOKIES CA! MINI INB - 7KG{br}
        CHR COOKIES OREO MINI INB - 7KG{br}
        CHR ARROWROOT CLUB PK - 1.4KG {br}
        CHR ARROWROOT - 350G{br}
        OREO GLUTEN FREE - 376G{br}
        OREO GLUTEN FREE - 342G{br}
        CHR OREO ORIG 4PK SC CLB - 2000G {br}
        OREO THINS ORIGINAL - 287G{br}
        OREO THINS GOLDEN - 287G{br}
        OREO THINS LEMON - 287G{br}
        OREO DARK CHOCOLATE - 303G{br}
        OREO FUDGE COVERED 224G{br}
        OREO THINS LATTE - 287G{br}
        OREO MINT FUDGE COVERED - 280G{br}
        OREO THINS DARK - 287G{br}
        OREO THINS ORIG - 257G{br}
        OREO THINS DARK - 257G{br}
        OREO ORIGINAL - 270G{br}
        OREO DOUBLE STUF - 261G{br}
        OREO GOLDEN - 270G{br}
        OREO GOLDEN DBL STUF - 261G{br}
        OREO BIRTHDAY - 261G{br}
        OREO MINT - 261G{br}
        OREO CINNAMON BUN - 261G{br}
        OREO DARK CHOCOLATE - 261G{br}
        OREO CARROT CAKE - 261G{br}
        CHIPS AHOY! MIDDLES CHOCHIP - 273G{br}
        CHR FUDGEE-O - 303G{br}
        CHR FUDGEE-O DOUBLE STUF - 303G{br}
        CHIPS AHOY! SFT CHUNKY ORIG 2 - 90G{br}
        NUTTER BUTTER FUDGE COVRD - 223G{br}
        CA! ORIGINAL - 258G{br}
        CA! CHUNKS - 251G{br}
        CA! CHUNKS TRIPCHOC - 251G{br}
        CA! CHEWY - 271G{br}
        CA! RAINBOW - 258G{br}
        OREO ORIGINAL - 500G{br}
        OREO GOLDEN - 500G{br}
        CHR FUDGEE-O - 500G{br}
        CHR FUDGEE-O - 500G{br}
        CHIPS AHOY! ORIGINAL 4 - 60G{br}
        CHIPS AHOY! ORIGINAL 4 - 60G OLYM{br}
        CHR OREO ORIG WIP - 500G{br}
        OREO DOUBLE STUF - 523G{br}
        CHIPS AHOY! CHEWY - 453G{br}
        CHIPS AHOY! RAINBOW - 457G{br}
        OREO THE MOST STUF - 379G{br}
        OREO MEGA STUF - 374G{br}
        OREO TIRAMISU - 432G{br}
        NUTTER BUTTER PNTBTR SDWCH - 453G{br}
        OREO CARAMEL COCONUT - 482G{br}
        OREO BIRTHDAY - 482G{br}
        CA! MINI EGGS 4 - 60G{br}
        CA! CARAMILK - 453G{br}
        OREO CHOC PNUT BTR PIE - 482G{br}
        OREO SNICKERDOODLE CINN - 345G{br}
        OREO NEAPOLITAN - 374G{br}
        OREO CHOC CONFETTI CAKE - 345G{br}
        OREO THE MOST OREO - 379G{br}
        OREO ORIG - 18PK 396G{br}
        EPK OREO VAR - 1.30KG{br}
        EPK MINI OREO CA! TG VAR - 1.35KG{br}
        EPK OREO RBS NB PIR FMLY - 1.41KG{br}
        EPK OREO CRSPS CARMLK SPK - 1.29KG{br}
        EPK OREO CA! FMLY VAR - 1.91KG{br}
        CHR FIG NEWTONS - 283G{br}
        CHR MINI OREO FUN TREATS - 450G{br}
        CHIPS AHOY! MINI FT - 450G{br}
        PEEK FREANS SOCIAL TEA - 525G{br}
        NUTTER BUTTER PB SGL SERVE - 56G{br}
        OREO ORIG 4PK - 45G {br}
        CA! ORIG 4PK - 57G{br}
        FULL PL OREO ORIG 2PK CLB - 880G{br}
        OREO PARTY PACK - 723G{br}
        CRISPERS SALT&VINEGAR RRP - 50G{br}
        CRISPERS ALL DRESSED RRP - 50G{br}
        CRISPERS BBQ RRP - 50G{br}
        CRISPERS SALT&VINEGAR BULK - 50G{br}
        CRISPERS BBQ BULK - 50G{br}
        CRISPERS ALL DRESSED BULK - 50G{br}
        CRISPERS CHEDDAR - 145G{br}
        CRISPERS DILL PICKLE - 145G{br}
        CRISPERS BBQ - 145G{br}
        CRISPERS RANCH - 145G{br}
        CRISPERS SALT&VINEGAR - 145G{br}
        CRISPERS ALL DRESSED - 145G{br}
        CRISPERS ALL DRESSED - 145G{br}
        CRISPERS KETCHUP - 145G{br}
        CRISPERS BITS & BITES ORIG - 145G{br}
        CRISPERS BITS & BITES CHEESE - 145G{br}
        CRISPERS BITS & BITES BBQ - 145G{br}
        CRISPERS JALAPENO - 145G{br}
        CRISPERS SALT&VINEGAR - 240G{br}
        CRISPERS BBQ - 240G{br}
        CRISPERS ALL DRESSED - 240G`,

        'products.group3.title' : 'Trident',
        'products.group3' : `DENTYNE ICE ASSTD 24PK - 288PC{br}
        TRID SLB FRESHMINT CLB - 14PC{br}
        TRID SLB SPEARMINT CLB - 14PC{br}
        TRID LAYERS STWBYCIT CLB - 14PC{br}
        TRIDENT ASSTD 24PK - 258PC{br}
        DENTYNE ICE AVALANCHE BTLS - 60PC{br}
        TRIDENT SLB SPEARMINT BTLS - 50PC{br}
        DENT ICE ARCT BUBBLE BTLS - 60PC{br}
        TRID VIBES SPRMT RUSH BTLS - 40PC{br}
        TRID VIBES TROP BEAT BTLS - 40PC{br}
        TRID VIBES SR BRY TWST BTLS - 40PC{br}
        TRID VIBES SPK BLRBRY BTLS - 40PC{br}
        TRID VIBES SPK REDBRY BTLS - 40PC{br}
        DENT WHITE SPEARMINT BTLS - 60PC{br}
        TRID SPLSH STRAW KIWI BTLS - 40PC{br}
        DENTYNE FIRE CINNAMON BTLS - 60PC{br}
        DENTYNE ICE SPEARMINT BTLS - 60PC{br}
        DENTYNE ICE PPRMT BTLS - 60PC{br}
        DENT ICE AVALANCHE 4PK - 48PC{br}
        DENT ICE ARCT BUBBLE 4PK - 48PC{br}
        TRID WHITE PEPPERMINT 4PK - 48PC{br}
        DENTYNE ICE PEPPERMNT 4PK - 48PC{br}
        DENTYNE ICE SPEARMINT 4PK - 48PC{br}
        DENTYNE ICE INTENSE 4PK - 48PC {br}
        DENTYNE ICE ASSTD 4PK - 48PC{br}
        DENTYNE FIRE CINNAMON 4PK - 48PC{br}
        TRID SPLASH VAN MINT 3PK - 27PC{br}
        TRID SPLSH STRAW KIWI 3PK - 27PC{br}
        TRIDENT PEL PPRMT 4PK - 48PC{br}
        TRIDENT PEL SPEARMINT 4PK - 48PC{br}
        DENTYNE ICE PEPPERMINT - 12PC{br}
        DENTYNE ICE MIDNIGHT MNT - 12PC{br}
        DENTYNE ICE AVALANCHE - 12PC{br}
        DENTYNE ICE ARCT BUBBLE - 12PC{br}
        DENT ICE SUBZ GLCR MINT - 12PC{br}
        DENT ICE SUBZ ICEBG MNT - 12PC{br}
        DENTYNE WHITE SPEARMINT - 12PC{br}
        DENTYNE FIRE CINNAMON - 12PC{br}
        DENTYNE ICE INTENSE - 12PC{br}
        DENTYNE ICE PEPPERMINT - 12PC{br}
        DENTYNE ICE SHIVER - 12PC{br}
        DENTYNE ICE SPEARMINT - 12PC{br}
        TRIDENT PEL SPEARMINT - 12PC{br}
        TRIDENT PEL PEPPERMINT - 12PC{br}
        TRIDENT SPLASH VAN MINT - 9PC{br}
        TRIDENT SPLASH STRAW KIWI - 9PC{br}
        DENTYNE FIRE CINNAMON 4PK - 48PC{br}
        DENTYNE ICE INTENSE 4PK - 48PC{br}
        DENTYNE ICE ASSTD 4PK - 48PC{br}
        TRID WHITE PEPPERMINT 4PK - 48PC{br}
        TRID PEL VERY BERRY 4PK - 48PC{br}
        DENTYNE ICE SPEARMINT 4PK - 48PC {br}
        DENTYNE ICE PEPPERMNT 4PK - 48PC{br}
        DENTYNE ICE ARCT BUBBLE 4PK - 48PC{br}
        DENTYNE ICE AVALANCHE 4PK - 48PC{br}
        TRID PEL PEPPERMINT 4PK - 48PC{br}
        DENT WHITE SPEARMINT 4PK - 48PC{br}
        TRIDENT SLB TROP TWST 3PK - 42PC{br}
        TRIDENT SLB BUBBLEGUM 3PK - 42PC{br}
        TRIDENT SLB WATERMELON 3PK - 42PC{br}
        TRIDENT SLB PEPPERMINT 3PK - 42PC{br}
        TRIDENT SLB SPEARMINT 3PK - 42PC{br}
        TRIDENT SLB SPEARMINT 3PK - 42PC{br}
        TRID LAYERS STRWBRY CIT 3PK - 42PC{br}
        TRIDENT SLB ORIGINAL - 14PC{br}
        TRIDENT SLB STRWBRY TWST - 14PC{br}
        TRIDENT SLB SPEARMINT - 14PC{br}
        TRIDENT SLB WTRMLN TWST - 14PC{br}
        TRIDENT SLB PEPPERMINT - 14PC{br}
        TRIDENT SLB TROP TWST - 14PC{br}
        TRIDENT SLB FRESHMINT - 14PC{br}
        TRIDENT SLB BUBBLEGUM - 14PC{br}
        TRID LAYERS STRWBRY CIT - 14PC{br}
        STRIDE SPEARMINT - 14PC`,

        //faq page
        'faq-heading-text1': 'Bien que le ',
            'faq-heading-link': 'Règlement officiel',
        'faq-heading-text2': ' décrive tous les détails de cette promotion, une réponse à vos questions a peut-être déjà été fournie ci-dessous.',

        'q1': 'Q. Quelle est la période de validité de cette promotion ?',
        'a1': 'A. Le concours Mondelez « Vous pourriez GAGNER un voyage Game Studios à Los Angeles » débute à 00:00 HE le  9 octobre 2022 et se termine à 23:59:59  HE le 17 décembre 2022.',
        
        'q2': 'Q. Quels sont les prix de cette promotion ?',
        'a2': 'A.  Il y a deux (2) Grands prix à gagner. Chaque prix est d’une valeur au détail approximative de 5 000 $ et doit être remporté entre le 1er mars et le 30 avril 2023. De plus, il y a cent (100) Prix quotidiens à gagner pour chaque jour de la Période de promotion.  Chaque Prix quotidien consiste en un code XBOX Game Pass d’un (1) mois (chacun, un « code »). Chaque Prix quotidien a une valeur au détail approximative de 11,99 $ CA.',
        
        'q2-b' : 'Q. Comment puis-je participer sans acheter un produit ?',
        'a2-b' : 'A. Pour participer au concours sans effectuer d’achat, vous pouvez demander à obtenir un UPC en envoyant un message unique et original de 25 mots expliquant pourquoi vous aimez jouer à des jeux vidéo, ainsi que votre nom complet, votre âge,et votre adresse courriel sur une feuille de papier de 8" x 11" (la "demande écrite ") et l’envoyer dans une enveloppe correctement affranchie au Canada à "Snack On With Xbox, 6 – 6150 Highway 7, Suite #154, Woodbridge, ON, L4H 0R6',
        
        'q3': 'Q. Combien de fois puis-je participer ?',
        'a3': 'A. Il y a une limite d’une (1) Participation admissible par adresse électronique/personne, par jour (quelle que soit la méthode ou la combinaison de méthodes de participation).',
        
        'q4': 'Q. Combien de prix puis-je gagner ?',
        'a4': 'A. Il y a une limite d’un (1) Grand prix par personne. Plus une limite de 2 Prix PC Game Pass quotidiens par personne pendant la Promotion.',

        'q5': 'Q. J’ai un UPC valide, mais je reçois une erreur.',
        'a5': 'A. Veuillez saisir le code sans tiret ni espace et consultez la liste des ',
        'a5a': 'produits participants',
        'a5b': ' pour vous assurer que votre achat est admissible. Si cela ne fonctionne toujours pas, nous sommes là pour vous aider! Veuillez nous contacter à ',

        'q6': 'Q. J’ai reçu un message m’indiquant que j’ai déjà participé aujourd’hui, mais ce n’est pas le cas. Que dois-je faire ?',
        'a6': 'A. Assurez-vous que vous visitez ',
            'a6-link': 'www.RechargeTesCollationsAvecXbox.ca',
            'a6-bottom': ' directement et non sur une page marquée d’un signet dans votre navigateur. Veuillez également essayer d’effacer les cookies et le cache de votre navigateur Internet.',

        'q7': 'Q. J’ai des problèmes techniques. Que dois-je faire ?',
        'a7': 'A. Parfois, il s’agit d’une chose simple, comme la mise à jour de votre navigateur. Vérifiez que vous disposez d’une version moderne de Chrome, Internet Explorer, Firefox ou Safari. Vous devriez également vérifier la section des préférences de votre navigateur pour vous assurer que JavaScript est activé. La suppression de votre cache et de vos cookies est également utile dans ce cas.',

        'q8': 'Q. Comment serai-je informé si je suis un gagnant potentiel du Prix quotidien ?',
        'a8': 'A. Vous serez informé à l’écran si vous avez été déclaré un gagnant potentiel d’un Prix quotidien. Chaque gagnant potentiel devra répondre correctement à un question d’habileté sur l’écran pour être déclaré un gagnant officiel.',

        'q9': 'Q. J’ai des difficultés à recevoir l’e-mail du PC Game Pass, que devrais-je faire ?',
        'a9': 'A. Veuillez vérifier votre courrier électronique et vos filtres anti-spam ou contactez-nous à l’adresse suivante ',

        'q10': 'Q. J’ai des difficultés à faire fonctionner mon PC Game Pass ? Que devrais-je faire ?',
        'a10': 'A. Pour plus de détails sur la façon d’échanger un code, visitez ',
        'a10-link': 'https://support.xbox.com/fr-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        'q11': 'Q. J’ai récemment mis à jour mon adresse électronique. Puis-je modifier mes informations d’inscription pour cette promotion ?',
        'a11': 'A. Nous vous prions de bien vouloir nous excuser pour tout inconvénient, mais en raison du nombre de participations que nous recevons, nous ne sommes pas en mesure de modifier les informations d’inscription. Si vous êtes inscrit au tirage au sort du Grand Prix pendant la période de promotion et que vos coordonnées changent, contactez ',
        'a11-2' : ' et fournissez vos informations mises à jour. {br}{br}Veuillez noter : Si vos coordonnées changent pendant la période de promotion, nous ne pouvons pas garantir que nous serons en mesure de satisfaire votre demande. {br}{br}Vous avez encore une question ? Veuillez nous contacter à ',
        'a11-3' : ' Veuillez noter que la réponse peut prendre jusqu’à trois (3) jours ouvrables.',

        //Rules page
        'rules-page-heading': 'RÈGLEMENT OFFICIEL',
    }
}