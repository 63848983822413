const preconteststate = -1;
const conteststate = 0;
const postconteststate = 1;

const contestStart = new Date("2022-10-01T04:00:00.000Z"); //
const contestEnd = new Date("2022-12-17T04:59:59.000Z");

export function getContestState(){

    // check qs state
    // const search = window.location.search.substring(1);
    let state = null;

    // check actual date.
    if (state===null){
        var today = window.reactInit.initdate; //new Date("2021/08/15 11:59:00"); // 
        // console.log('checkdate',today);
        if (today>contestEnd){
            state = postconteststate;
        } else if (today>=contestStart){
            state = conteststate;
        } else {
            state = preconteststate;
        }
    }

    return state;
}